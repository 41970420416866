export default function(componentGetMethod) {
    return {
      watch: {
        '$route.query': {
          handler(newQuery, oldQuery) {
            if (JSON.stringify(newQuery) !== JSON.stringify(oldQuery)) {
              this.initializeFromQuery();
              this.$nextTick(() => {
                componentGetMethod.call(this, { ...newQuery });
              });
              setTimeout(()=>{
                window.scrollTo(0, this.$store.getters.getScrollY);
                this.$store.commit('setScrollY', scrollY)
              }, 500)
              
            }
          },
          immediate: true,
        },
      },
      methods: {
        updatedQueryParams(query) {
          const cleanedQuery = Object.fromEntries(
            Object.entries(query).filter(([, value]) => value !== null && value !== undefined && value !== '' && value)
          );
          this.$router.push({ query: { ...cleanedQuery } });
        },
      }
    };
  }